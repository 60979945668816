@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@400;500&display=swap");

:root {
  --font-family: "Poppins";
  --font-body: "Inter";

  --gradient-text: linear-gradient(
    187deg,
    rgba(255, 255, 247, 1) 20%,
    rgba(114, 114, 103, 1) 80%
  );
  --gradient-text1: linear-gradient(190deg, #262626 30%, #cccccc 60%);

  --color-bg: #0d0d0d;
  --color-footer: #171717;
  --color-text: #cccccc;
  --color-textbody: #7d7f78;
  --color-subtext: #ddff00;
}
