.genz__contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.genz__contacts-intro p {
  color: var(--color-subtext);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.genz__contacts-intro h2 {
  padding: 2rem;
  color: var(--color-textbody);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
}
.genz__contacts-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 80%;
}
.genz__contacts-form p {
  color: var(--color-subtext);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  padding: 2rem;
}
.genz__contacts-form form {
  margin-top: 2rem;
}
.genz__contacts-form_col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.genz__contacts-form_button button {
  padding: 1rem 1.5rem;
  color: #cccccc;
  background: #171717;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  border-radius: 25px;
  border: 0;
  outline: none;
  cursor: pointer;
}
.genz__contacts-form_button {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* learn */
.single_form {
  margin: 0 2rem 2rem;
}
.single_form input[type="text"],
.single_form input[type="email"],
.single_form input[type="tel"],
.single_form textarea {
  width: 100%;
  background: transparent;
  height: 41px;
  border: none;
  border-bottom: 2px solid #292929;
  font-size: 14px;
}
.single_form textarea {
  resize: none;
  height: 143px;
  margin-bottom: 20px;
}
input,
select,
textarea {
  color: var(--color-subtext);
}
.single_form label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 0.8;
  color: #fff;
  cursor: pointer;
}
