.genz__work-containers1_content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: rgba(23, 23, 23, 60%);
  margin: 2rem 1rem;
  border-radius: 15px;
  width: 450px;
  height: 350px;
  box-shadow: rgba(17, 18, 19, 0.4) 0px 0px 0px 2px,
    rgba(24, 24, 24, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.genz__work-containers_content__text h3 {
  padding-top: 2rem;
  font-family: var(--font-family);
  color: var(--color-text);
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.02em;
}
.genz__work-containers_content__text p {
  padding-top: 1rem;
  font-family: var(--font-body);
  color: #7a7a7a;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.01em;
}
.genz__work-containers1_content__image{
  height: 50%;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}