.genz__cta {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.genz__cta-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-width: 70%;
  margin: 0 auto;
}
.genz__cta-content_text {
  padding-right: 2rem;
}
.genz__cta-content_text p {
  color: var(--color-subtext);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
}
.genz__cta-content_text h2 {
  color: var(--color-text);
  font-family: var(--font-family);
  font-size: 38px;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.03em;
}

.genz__cta-content_image {
  align-items: center;
}
.genz__cta-content_image img {
  max-width: 610px;
  max-height: 433px;
  border-radius: 20px;
  box-shadow: rgba(26, 26, 26, 0.2) 0px 12px 28px 0px,
    rgba(82, 82, 82, 0.1) 0px 2px 4px 0px,
    rgba(130, 130, 130, 0.05) 0px 0px 0px 1px inset;
}
