.genz__header {
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
}
.genz__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.genz__header-content_center {
  flex-direction: column;
}

.genz__header-content h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 80px;
  line-height: 115%;
  text-align: center;
  letter-spacing: -0.03em;
}
.genz__header-content p {
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--color-textbody);
  margin-top: 2rem;
  margin-left: 10rem;
  margin-right: 10rem;
}
.genz__header-content__input {
  width: 50%;
  margin: 3rem auto 1rem;
  display: flex;
}
.genz__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  background: var(--color-bg);
  border-radius: 25px 0 0 25px;
  border: 1px solid #7d7f78;
  padding: 0 1rem;
  outline: none;

  font-family: var(--font-family);
  font-weight: 400;
  color: var(--color-text);
  font-size: 16px;
  line-height: 130%;
}
.genz__header-content__input button {
  flex: 1;
  width: 100%;
  min-height: 50px;
  background: var(--color-subtext);
  border-radius: 0 25px 25px 0;
  border: 1px solid #7a7a7a;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  color: var(--color-bg);
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  border: none;
  box-shadow: rgba(221, 255, 0, 1) 0px 25px 50px -12px;
}

/* images */
.genz__header-content_image {
  flex: 1;
  display: flex;
  margin: auto 2rem 0rem;
  justify-content: center;
  align-items: baseline;
}
.genz__header-content img {
  position: relative;
  width: 207px;
  height: 212px;
  border-radius: 5px;
  /* align-items: baseline; */
}
@media screen and (max-width: 1050px) {
  .genz__header {
    flex-direction: column;
  }
  .genz__header-content {
    margin: 0 0 3rem;
  }
  .genz__header p {
    margin-left: auto;
    margin-right: auto;
  }
  .genz__header-content__input {
    width: 80%;
  }
  .genz__header-content_image {
    align-items: center;
    margin-bottom: 10rem;
  }
}
@media screen and (max-width: 650px) {
  .genz__header h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .genz__header p {
    font-size: 16px;
    line-height: 24px;
  }
  .genz__header-content {
    margin: 0 0 3rem;
  }
  .genz__header-content_image {
    display: none;
    margin: 5rem;
  }
  .genz__header-content__input {
    width: 100%;
  }
}
.genz__header-images {
  flex: 1;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: baseline;
  flex-direction: row;
  margin: auto 5rem 0rem;
  height: 50px;
  display: flex;
}
.genz__header-images img {
  margin: auto 5rem 0rem;
}
.genz__header-image1 img {
  width: 266px;
  height: 302px;
  border-radius: 5px;
}
.genz__header-image2 img {
  width: 280px;
  height: 194px;
  border-radius: 5px;
}
.genz__header-image3 img {
  width: 272px;
  height: 294px;
  border-radius: 5px;
}
