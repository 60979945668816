.genz__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  padding: 2rem 4rem;
}

.genz__footer-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.genz__footer-links-logo img {
  width: 50px;
  height: 51px;
  opacity: 0.4;
}
.genz__footer-links-container {
  display: flex;
  flex-direction: row;
}
.genz__footer-links-container p {
  color: #7a7a7a;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 0 1rem;
  /* cursor: pointer; */
}
