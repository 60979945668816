.genz__service {
  display: flex;
  flex-direction: column;
}
.genz__service-topic {
  justify-content: center;
  align-items: center;
}
.genz__service-topic p {
  color: var(--color-subtext);
  font-family: var(--font-body);
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  text-align: center;
}
.genz__service-topic h2 {
  padding-top: 1rem;
  font-family: var(--font-family);
  color: var(--color-textbody);
  font-weight: 400;
  font-size: 44px;
  line-height: 120%;
  text-align: center;
  letter-spacing: -0.03em;
}

.genz__service-containers {
  /* padding-top: 2rem; */
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.genz__service-containers1_content {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: rgba(23, 23, 23, 60%);
  margin: 2rem 2rem 0 2rem;
  border-radius: 15px;
  width: 400px;
  height: 500px;
  box-shadow: rgba(17, 18, 19, 0.4) 0px 0px 0px 2px,
    rgba(24, 24, 24, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.genz__service-containers2_content {
  display: flex;
  flex-direction: row;
  padding: 2rem;
  background: rgba(23, 23, 23, 60%);
  margin: 0 2rem;
  border-radius: 15px;
  width: 700px;
  height: 250px;
  box-shadow: rgba(17, 18, 19, 0.4) 0px 0px 0px 2px,
    rgba(24, 24, 24, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.genz__service-containers1_content__image {
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.genz__service-containers1_content__image img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
.genz__service-containers_content__text h3 {
  padding-top: 2rem;
  font-family: var(--font-family);
  color: var(--color-text);
  font-weight: 400;
  font-size: 26px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.02em;
}
.genz__service-containers_content__text p {
  padding-top: 1rem;
  font-family: var(--font-body);
  color: #7a7a7a;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.01em;
}
.genz__service-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.genz__service-container__text {
  display: flex;
  flex-direction: row;
  max-height: 3rem;
  padding: 1rem;
  background: rgba(23, 23, 23, 60%);
  margin: 1rem;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(17, 18, 19, 0.4) 0px 0px 0px 2px,
    rgba(24, 24, 24, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}
.genz__service-container__text p {
  padding-top: 1rem;
  font-family: var(--font-body);
  color: #7a7a7a;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: left;
  letter-spacing: -0.01em;
}
