.genz__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  padding: 2rem 4rem;
  /* position: fixed; */
}

.genz__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-footer);
  height: 3.5rem;
  max-width: 40%;
  border-radius: 25px;
  box-shadow: rgba(204, 204, 204, 0.25) 0px 0px 10px;
  /* position: fixed; */
}

.genz__navbar-links-logo img {
  width: 50px;
  height: 51px;
}
.genz__navbar-links-container {
  display: flex;
  flex-direction: row;
  position: fixed;
}
.genz__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.genz__navbar-links-container p,
.genz__navbar-sign p,
.genz__navbar-menu_container p {
  color: #7a7a7a;
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  margin: 0 1rem;
  cursor: pointer;
}

.genz__navbar-sign button,
.genz__navbar-menu_container button {
  padding: 1rem 1.5rem;
  color: #cccccc;
  background: #171717;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  border-radius: 25px;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: rgba(204, 204, 204, 0.25) 0px 0px 10px;
}

.genz__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.genz__navbar-menu svg {
  cursor: pointer;
}

.genz__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: right;
  background: var(--color-footer);
  opacity: 80%;
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;

  margin-top: 1rem;
  width: 215px;
  border-radius: 20px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
  /* filter: blur(5px); */
}

.genz__navbar-menu_container p {
  margin: 1rem 0;
}

@media screen and (max-width: 700px) {
  .genz__navbar {
    padding: 2rem 4rem;
  }
  .genz__navbar-links {
    display: none;
  }
  .genz__navbar-menu {
    display: flex;
  }
}
@media screen and (max-width: 550px) {
  .genz__navbar {
    padding: 2rem;
  }
  .genz__navbar-sign {
    display: none;
  }
  .genz__navbar-menu_container {
    top: 20px;
  }
}

