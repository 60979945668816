.genz__client-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.genz__client {
  display: flex;
  flex-direction: column;
}
.genz__client p {
  font-family: var(--font-body);
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--color-textbody);
  margin-top: 2rem;
  padding-bottom: 2rem;
}

.genz__client-image img {
  flex: 1;
  max-width: 250px;
  min-width: 150px;
  height: 50px;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
